import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Cremefarben"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            Cremefarben ist nicht nur im Sommer in Trend, denn auch diesen Winter ist es nicht so
            dunkel wie gedacht. Cremefarbene Bekleidung ist angesagt. Ann-Kathrin Götze zeigt uns in
            ihrem Instagram-Beitrag wie schön beige aussehen kann. Ein Blazer und eine Anzugshose in
            beige von PAUL &amp; JOE. Die cremefarbenen Pullover haben es uns in dieser kalten
            Jahreszeit angetan: ein Longpullover mit Patentmuster in creme von HALLHUBER oder der
            Strickpullover in creme von Esprit. Wer eine elegante Bluse in beige tragen möchte, der
            findet bei den Marken FIGL und s.Oliver BLACK LABEL einzigartiges.
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B6bKEgIoqlA/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608933523&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/37058187?w=1731&h=2500",
                    title: "",
                  },
                ],
                name: "FIGL Blazer",
                price: 108.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25545996091&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/36993357?w=1500&h=1900",
                    title: "",
                  },
                ],
                name: "GERRY WEBER Blazer",
                price: 139.99,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=24609100451&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34180358?w=1524&h=2200",
                    title: "",
                  },
                ],
                name: "Usha Stoffhose",
                price: 59.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25124670963&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35661859?w=1524&h=2200",
                    title: "",
                  },
                ],
                name: "Usha Stoffhose",
                price: 129.95,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25506636845&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/36868777?w=1060&h=1500",
                    title: "",
                  },
                ],
                name: "s.Oliver BLACK LABEL Bluse",
                price: 59.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24672831327&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/37061532?w=1731&h=2500",
                    title: "",
                  },
                ],
                name: "FIGL Bluse",
                price: 87.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608593875&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/30914855?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Apart Bluse",
                price: 139.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25061306335&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35266705?w=1060&h=1500",
                    title: "",
                  },
                ],
                name: "s.Oliver BLACK LABEL Strick-Pullover",
                price: 49.99,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
